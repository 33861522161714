<template>
  <div>
    <img
        v-for="(item,index) in imgList "
        :key="index"
        class="income-img"
        :src="item.src"
        alt=""
        @click="link(item.link)"
        style="max-width:500px"
    >

    <div
        class="bg"
        style="position: relative;width:100%;z-index:99;"
    >
      <div
          style="width:340px;height:200px;margin:0 auto;position:absolute;top:20%;left:50%;transform:translateX(-50%);background-color:#fff;border-radius: 10%;">
        <div
            style="width:74px;height:74px;border-radius: 50%;position: absolute;top:5%;left:38.9%;background-color:#f2fd95;overflow: hidden;">
          <img
              style="width:100%;height:100%"
              :src="user.headimgurl"
              alt=""
          >
        </div>
        <p style="font-size:18px;color:#967b3c;margin: 0;position: absolute;top:95px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          {{ nicknameFn(user.nickname, 9) }}</p>
        <p style="font-size:16px;color:#967b3c;margin: 0;position: absolute;top:120px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          助力人数： {{ assist_num }} 人</p>
        <p style="font-size:16px;color:#967b3c;margin: 0;position: absolute;top:145px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          共获得收益： {{ total }} 元</p>
        <p style="font-size:16px;color:#967b3c;margin: 0;position: absolute;top:170px;left: 50%;text-align:center;transform: translateX(-50%);min-width: 180px;">
          已领取收益： {{ pay_record }} 元</p>
      </div>
    </div>
    <!--    <div-->
    <!--      style="width:100%;margin:0 auto;position:absolute;left:50%;transform:translateX(-50%);background-color:#fff;"-->
    <!--      v-if="false"-->
    <!--    >-->
    <!--      &lt;!&ndash; <div-->
    <!--        style="font-size:16px;color:rgb(150, 123, 60);text-align:end;padding-right:15px;position: absolute;right: 10px;top: 10px;"-->
    <!--        @click="goRanings"-->
    <!--      >查看助力排行榜</div> &ndash;&gt;-->
    <!--      <div-->
    <!--        v-for="(item,index) in assist"-->
    <!--        :key="index"-->
    <!--        style="width:100%;height:80px;padding: 0 26px;display:flex;justify-content:space-between;align-items: center;border-bottom: 1px solid #ccc; font-size:16px"-->
    <!--      >-->
    <!--        <div style="display: flex;align-items: center;">-->
    <!--          &lt;!&ndash; <div style="font-size:18px;min-width: 30px;">{{item.index}}</div> &ndash;&gt;-->
    <!--          <div style="width: 60px;height:60px;border-radius:50%;overflow: hidden;margin-left: 5px;">-->
    <!--            <img-->
    <!--              style="width:100%;height:100%"-->
    <!--              :src="item.headimgurl"-->
    <!--              alt=""-->
    <!--            >-->
    <!--          </div>-->
    <!--          <div style="margin-left: 20px;font-size: 14px;">{{nicknameFn(item.nickname,9)}}</div>-->
    <!--        </div>-->

    <!--      </div>-->

    <!--    </div>-->

  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      // imgList: [{ src: "https://ceshiren.com/uploads/default/original/3X/4/9/4908d9cca3d3e96880902b88d759df46316f7584.png" }, { src: "https://ceshiren.com/uploads/default/original/3X/4/9/4908d9cca3d3e96880902b88d759df46316f7584.png" }],
      // assist: [
      //   { nickname: '霍格沃兹', headimgurl: 'https://thirdwx.qlogo.cn/mmopen/vi_32/ycAHHJtxXuHTCB4aOSWLBxaR3eHPqtTVWZVsDhpC8wrDRHkicWzqsACVDA7hgb3Fk1TtRMnE0R3vfZq1ias3NEIA/132' },
      //   { nickname: '霍格沃兹', headimgurl: 'https://thirdwx.qlogo.cn/mmopen/vi_32/ycAHHJtxXuHTCB4aOSWLBxaR3eHPqtTVWZVsDhpC8wrDRHkicWzqsACVDA7hgb3Fk1TtRMnE0R3vfZq1ias3NEIA/132' },
      //   { nickname: '霍格沃兹', headimgurl: 'https://thirdwx.qlogo.cn/mmopen/vi_32/ycAHHJtxXuHTCB4aOSWLBxaR3eHPqtTVWZVsDhpC8wrDRHkicWzqsACVDA7hgb3Fk1TtRMnE0R3vfZq1ias3NEIA/132' }
      // ],
      // user: {
      //   nickname: '霍格沃兹',
      //   headimgurl: "https://thirdwx.qlogo.cn/mmopen/vi_32/ycAHHJtxXuHTCB4aOSWLBxaR3eHPqtTVWZVsDhpC8wrDRHkicWzqsACVDA7hgb3Fk1TtRMnE0R3vfZq1ias3NEIA/132",
      // },

      imgList: [],
      assist: [],
      user: {
        nickname: '',
        headimgurl: ''
      },
      total: 0,
      pay_record: 0,
      assist_num: 0,
      title: '',
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    window.vue = this;
    console.log(this.title);
    document.title = this.title
  },
  watch: {
    title: {
      handler(newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    }
  },
  methods: {

    getData() {
      let origin_ = window.location.origin
      // //url参数解密
      // let data = this.$resCommon.resDecrypt(this.$route.query.data)
      // console.log(data);
      // // 发送请求参数加密
      // let reqParams = this.$resCommon.reqEncrypt(data)
      let params = {
        project: this.$route.query.project,
        uid: this.$route.query.uid,
      }
      // axios.post(`${origin_}/backend/sale/income`, { data: this.$route.query.data })
      axios.get(`${origin_}/backend/sale/income`, {params})
          .then(res => {
            //解密响应数据
            // let resData = this.$resCommon.getResData(res)
            let resData = res.data.data;
            console.log(resData);
            this.imgList = resData.img;
            this.user = resData.user;
            this.total = resData.total;
            this.pay_record = resData.pay_record;
            this.assist_num = resData.assist_num;
            this.title = resData.title;
          })
    },

    goRanings() {
    },

    //图片跳转
    link(url) {
      if (url) {
        location.href = url
      }
    },

    //昵称处理
    nicknameFn(nickname, num) {
      console.log(nickname);
      if (nickname && nickname.length > num) {
        return nickname.substring(0, num) + '···'
      } else {
        return nickname
      }
    },
  }
}
</script>

<style scoped>
.income-img {
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.bg {
  width: 100%;
  height: 200px;
  background-color: #fb8c00;
  border-bottom-left-radius: 30%;
  border-bottom-right-radius: 30%;
}
</style>